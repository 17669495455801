import gql from 'graphql-tag';

const queries = {
	VideoTapsFindOne: gql`
		query VideoTapsFindOne($filter: FilterFindOnevideotapInput, $skip: Int, $sort: SortFindOnevideotapInput){
            VideoTapsFindOne( filter: $filter, skip: $skip, sort: $sort ){
                _id
                lesson
                start
                end
                sparkLibrary
                tag
                deletedAt
                createdAt
                updatedAt
			}
		}
	`,
	VideoTapsFindMany: gql`
		query VideoTapsFindMany($filter: FilterFindManyvideotapInput, $skip: Int, $limit: Int, $sort:SortFindManyvideotapInput) {
            VideoTapsFindMany(filter: $filter, skip: $skip, limit: $limit ,sort: $sort){
                _id
                lesson
                start
                end
                sparkLibrary
                tag
                deletedAt
                createdAt
                updatedAt
			}
		}
	`
};

export const LessonQueries = ( value ) => {
	return queries[ value ];
};