import {ArrowIndicatorIcon} from '../../icons';
import {useParams} from 'react-router-dom';
import {DocsTab, ExtraQuestionView, Info, QuestionsView, VideoTap} from './views';
import {LessonsTab} from './components';
import PropTypes from 'prop-types';

export const LessonsEdit = ({ id, history, lesson }) => {
	const { tab } = useParams();
	return (
		<div className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Lesson</h1>

			<section className={'bg-midnight-default min-h-[70vh] rounded-2xl  flex flex-col gap-2  align-center'}>

				<div className={'mb-4 w-100 flex gap-3 justify-center align-center self-start'}>
					<LessonsTab label={'info'} to={`/lessons/${id}/info`} />
					<LessonsTab label={'Quizzes'} to={`/lessons/${id}/question`} />
					<LessonsTab label={'Journal Questions'} to={`/lessons/${id}/extra_question`} />
					<LessonsTab label={'Docs'} to={`/lessons/${id}/docs`} />
					<LessonsTab label={'VideoTab'} to={`/lessons/${id}/video_tab`} />
				</div>


				{tab === 'info' ? <Info lesson={lesson} /> : null}
				{tab === 'question' ? <QuestionsView lesson={lesson} /> : null}
				{tab === 'docs' ? <DocsTab lesson={lesson} /> : null}
				{tab === 'extra_question' ? <ExtraQuestionView lesson={lesson} /> : null}
				{tab === 'video_tab' ? <VideoTap lesson={lesson} /> : null}
			</section>
		</div>
	);
};

LessonsEdit.propTypes = {
	id: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	lesson: PropTypes.object.isRequired
};