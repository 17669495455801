import gql from 'graphql-tag';

const mutations = {
	VideoTapsCreateOne: gql`
        mutation VideoTapsCreateOne($record: CreateOnevideotapInput!){
            VideoTapsCreateOne(record: $record){
                recordId
                record {
                    _id
                    lesson
                    start
                    end
                    sparkLibrary
                    tag
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
			}	
		},
	`,
	VideoTapsUpdateOne: gql`
		mutation VideoTapsUpdateOne($_id: String!, $record: UpdateByIdvideotapInput!) {
            VideoTapsUpdateOne(_id: $_id, record: $record){
                recordId
                record {
                    _id
                    lesson
                    start
                    end
                    sparkLibrary
                    tag
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
			}
		}
	`,
	VideoTapsDeleteOne: gql`
		mutation VideoTapsDeleteOne($videotap: String!){
            VideoTapsDeleteOne(videotap: $videotap)
		}
	`
};

export const LessonsMutations = ( value ) => {
	return mutations[ value ];
};