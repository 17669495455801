import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {query} from '../../actions/queries';
import {useMutation, useQuery} from '@apollo/client';
import {mutations} from '../../actions/mutations';
import {Loader, Search} from '../../components';
import DeleteConfirmationModal from '../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import {
	MoodPageContainer,
	MoodsTitle,
	MoodsWrapperElements,
	StyledGrid
} from './Mood.style';
import {ImageIcon, PencilIcon, TrashIcon} from '../../icons';

export const Mood = () => {
	const history = useHistory();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [moodToDelete, setMoodToDelete] = useState(null);

	const {loading, error, data, refetch} = useQuery(query('MoodFindMany'), {
		variables: {
			filter: {
				deletedAt: null,
			}
		},
		nextFetchPolicy: 'network-only'
	});

	const [deleteMood] = useMutation(mutations.MoodDeleteOne, {
		refetchQueries: ['MoodFindMany'],
		awaitRefetchQueries: true
	});

	const handleDeleteClick = (moodId) => {
		setMoodToDelete(moodId);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		try {
			await deleteMood({
				variables: {mood: moodToDelete}
			});
			setIsDeleteModalOpen(false);
			await refetch();
		} catch (error) {
			console.error('Error deleting mood:', error);
		}
	};

	const sortedData = data ? data.MoodFindMany : [];

	if (loading) return <div><Loader/></div>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<MoodPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<MoodsTitle className={'font-sans'}>Moods</MoodsTitle>
				<Search onSearch={() => {
				}} style={{marginLeft: '3rem', maxWidth: '350px'}}/>
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<button
					style={{maxWidth: '340px'}}
					onClick={() => history.push('/mood/create')}
					className={`h-full flex self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
				>
					Create Mood
				</button>
				{sortedData.map(({_id, label, cover, description}) => (
					<MoodsWrapperElements
						key={_id}
						className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}>
						<div className={'flex justify-start'}>
							<div className={'flex justify-start items-center mr-2'}>
								{
									cover?.url === undefined || cover?.url === null || cover?.url === 'test' || cover?.url === '' ?
										(<div
											className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
											<ImageIcon width={'1rem'} height={'1rem'}
													   background={'rgba(174, 185, 225, 1)'}/>
										</div>)
										:
										(
											<img src={cover?.url} alt={label}/>
										)
								}
							</div>
							<div className={'grid'}>
								<span className={'font-sans '}>{label}</span>
								<p className={'font-mono'}>{description ? description : 'No description yet'}</p>
							</div>
						</div>
						<div className={'flex justify-center align-center'}>
							<div onClick={() => history.push(`/mood/${_id}`)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<PencilIcon/>
							</div>
							<div onClick={() => handleDeleteClick(_id)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<TrashIcon/>
							</div>
						</div>
					</MoodsWrapperElements>
				))}
			</StyledGrid>
			<DeleteConfirmationModal
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				onDelete={handleDelete}
				itemName={moodToDelete && sortedData.find(mood => mood._id === moodToDelete)?.label}
			/>
		</MoodPageContainer>
	);
};