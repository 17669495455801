import React, {useState} from 'react';
import { useQuery } from '@apollo/client';
import {LessonQueries} from '../../actions/Lessons.queries';;
import {VideoTapRender} from './VideoTap.render';
import {Loader} from '../../../../components';
import PropTypes from 'prop-types';

export const VideoTapLoad = ({ lesson }) => {
	const [showVideoTapForm, setShowVideoTapForm] = useState(false);
	const [showVideoTapEditForm, setShowVideoEditTapForm] = useState(false);
	const [selectedVideoTapId, setSelectedVideoTapId] = useState(null);
	const { data: VideoTapsData, loading: VideoTapsLoading, error: VideoTapsError } =
		useQuery(LessonQueries('VideoTapsFindMany'), {
			variables: { filter: { lesson: lesson._id }, skip: 0, limit: 100, sort: '_ID_ASC' },
		});

	if (VideoTapsLoading) return <Loader />;
	if (VideoTapsError) return <span className={'font-mona f-600 white p-3'}>Error...</span>;

	const handleEditClick = (id) => {
		setSelectedVideoTapId(id);
		setShowVideoEditTapForm(true);
	};

	return (
		<VideoTapRender
			lesson_id={lesson._id}
			VideoTapsData={VideoTapsData}
			showVideoTapForm={showVideoTapForm}
			showVideoTapEditForm={showVideoTapEditForm}
			setShowVideoEditTapForm={setShowVideoEditTapForm}
			setShowVideoTapForm={setShowVideoTapForm}
			handleEditClick={handleEditClick}
			selectedVideoTapId={selectedVideoTapId}
			setSelectedVideoTapId={setSelectedVideoTapId}
		/>
	);
};

VideoTapLoad.propTypes = {
	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}).isRequired,
};